(function($){

	//Browser Detection Function
	function addBrowserClass(){
		// Opera 8.0+
		var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
		if(isOpera)
			$('body').addClass('is-opera');
		// Firefox 1.0+
		var isFirefox = typeof InstallTrigger !== 'undefined';
		if(isFirefox)
			$('body').addClass('is-firefox');
		// Safari 3.0+ "[object HTMLElementConstructor]" 
		var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
		if(isSafari)
			$('body').addClass('is-safari');
	    // Internet Explorer 6-11
	    var isIE = /*@cc_on!@*/false || !!document.documentMode;
		if ( isIE )// If Internet Explorer, add class IE
		    $('body').addClass('is-ie');
		else
		    $('body').addClass('not-ie');	
	    // Edge 20+
	    var isEdge = !isIE && !!window.StyleMedia;
		if ( isEdge )// If Internet Explorer, add class IE
		    $('body').addClass('is-edge');
		// Chrome 1+
		var isChrome = !!window.chrome && !!window.chrome.webstore;
		if(isChrome)
			$('body').addClass('is-chrome');
	}
	
	addBrowserClass();

 	$( window ).scroll( function() {

 		if( $( window ).width() > 782 ) {

		    if( $( document ).scrollTop() > 0 ) {

		        if( $( '.site-header' ).data( 'size' ) == 'big' ) {

		            $( '.site-header' ).data( 'size','small' );

		            $( '.site-header' ).addClass( 'fixed' );

		            $( '.site-header' ).removeClass( 'absolute' );
		        }

		    } else {

		        if( $( '.site-header' ).data( 'size' ) == 'small') {

		            $('.site-header').data('size','big');

					$( '.site-header' ).removeClass( 'fixed' );

					$( '.site-header' ).addClass( 'absolute' );

		        }  

		    }
 			
 		}

  	});

  	if( window.location.hash ) {

  		setTimeout( function() {

		    window.scrollTo( 0, 0 );

	  	}, 1);
  		
		var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character

      	$( 'html, body' ).animate({

	        scrollTop: $( "#" + hash ).offset().top - 1000

	    }, 1500);

	}

	$( '#testimonials-slider' ).slick({

		centerMode: true,
        arrows: true,
		prevArrow: $('.ts-prev'),
		nextArrow: $('.ts-next'),
		dots: true,
		slidesToShow: 1,
		variableWidth: true,
		draggable: false,
		adaptiveHeight: true,
		responsive: [

		    {
		      	breakpoint: 768,
		      	settings: {
			        arrows: false,
			        dots: false,
			        centerMode: true,
			        draggable: true
		      	}

		    },

		    {
		      	breakpoint: 480,
		     	settings: {
			        arrows: false,
			        dots: false,
			        centerMode: false,
			        draggable: true,
			        variableWidth: false
		      	}

		    }

	  	]

	});

	$( '#s4-cl-slider' ).slick({
        
        arrows: false,
		dots: false,
		slidesToShow: 1,
		draggable: true,
		variableWidth: true

	});

	$( '#s5-list-slider' ).slick({
        
        arrows: true,
		prevArrow: $('.s5-mv-prev'),
		nextArrow: $('.s5-mv-next'),
		dots: false,
		slidesToShow: 1,
		draggable: true,
		variableWidth: true

	});


})(jQuery);