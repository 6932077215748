(function($){
	$( '.home' ).prepend( '<span id="map-tip"></span>' );
	$( '.page-template-page-coverage' ).prepend( '<span id="map-tip"></span>' );
	$( '.home' ).prepend( '<div id="map-icon"></div>' );
	$( '.page-template-page-coverage' ).prepend( '<div id="map-icon"></div>' );

	// Quick feature detection
	function isTouchEnabled() {
		return (('ontouchstart' in window)
			|| (navigator.MaxTouchPoints > 0)
			|| (navigator.msMaxTouchPoints > 0));
	}

	addEvent('map_1');
	addEvent('map_2');
	addEvent('map_3');
	addEvent('map_4');
	addEvent('map_5');
	addEvent('map_6');
	addEvent('map_7');
	addEvent('map_8');
	addEvent('map_9');
	addEvent('map_10');
	addEvent('map_11');
	addEvent('map_12');
	addEvent('map_13');
	addEvent('map_14');
	addEvent('map_15');
	addEvent('map_16');
	addEvent('map_17');
	addEvent('map_18');
	addEvent('map_19');
	addEvent('map_20');
	addEvent('map_21');
	addEvent('map_22');
	addEvent('map_23');
	addEvent('map_24');
	addEvent('map_25');
	addEvent('map_26');
	addEvent('map_27');
	addEvent('map_28');
	addEvent('map_29');
	addEvent('map_30');
	addEvent('map_31');
	addEvent('map_32');
	addEvent('map_33');
	addEvent('map_34');
	addEvent('map_35');
	addEvent('map_36');
	addEvent('map_37');
	addEvent('map_38');
	addEvent('map_39');
	addEvent('map_40');
	addEvent('map_41');
	addEvent('map_42');
	addEvent('map_43');
	addEvent('map_44');
	addEvent('map_45');
	addEvent('map_46');
	addEvent('map_47');
	addEvent('map_48');
	addEvent('map_49');
	addEvent('map_50');
	addEvent('map_51');
	addEvent('map_52');
	addEvent('map_53');
	addEvent('map_54');
	addEvent('map_55');
	addEvent('map_56');
	addEvent('map_57');
	addEvent('map_58');
	addEvent('map_59');
	addEvent('map_60');
	addEvent('map_61');
	addEvent('map_62');
	addEvent('map_63');
	addEvent('map_64');
	addEvent('map_65');
	addEvent('map_66');
	addEvent('map_67');

	function addEvent(id,relationId){

		if( map_config !== "" ) {
			var _obj = $('#'+id);
			_obj.attr({'fill':map_config[id]['upColor'],'stroke':map_config['default']['borderColor']});
			_obj.attr({'cursor':'default'});

			if(map_config[id]['enable'] == true){
				$('#mobile-map-dropdown').append('<option value="'+id+'">'+map_config[id]['hover']+'</option>');

				if (isTouchEnabled()) {
					_obj.on('touchstart', function(e){
						var touch = e.originalEvent.touches[0];
						var x=touch.pageX+10, y=touch.pageY+15;
						var tipw=$('#map-tip').outerWidth(), tiph=$('#map-tip').outerHeight(), 
						x=(x+tipw>$(document).scrollLeft()+$(window).width())? x-tipw-(20*2) : x
						y=(y+tiph>$(document).scrollTop()+$(window).height())? $(document).scrollTop()+$(window).height()-tiph-10 : y
						$('#'+id).css({'fill':map_config['default']['downColor']});
						$('#map-tip').show().html(map_config[id]['hover']);
						$('#map-tip').css({left:x + 20, top:y - 85});
						$('#map-icon').hide();
					})
					_obj.on('touchend', function(){
						$('#'+id).css({'fill':map_config[id]['upColor']});
						// display_map_details(id);
					})
				}
				// _obj.attr({'cursor':'pointer'});
				_obj.hover(function(){
					$('#map-tip').show().html(map_config[id]['hover']);
					$('#map-icon').show();
					_obj.css({'fill':map_config['default']['overColor']})
				},function(){
					$('#map-tip').hide();
					$('#map-icon').hide();
					$('#'+id).css({'fill':map_config[id]['upColor']});
				})
				_obj.mousedown(function(){
					$('#'+id).css({'fill':map_config['default']['downColor']});
				})
				_obj.mouseup(function(){
					$('#'+id).css({'fill':map_config['default']['overColor']});
					// display_map_details(id);
				})
				_obj.mousemove(function(e){
					var x=e.pageX+10, y=e.pageY+15;
					var tipw=$('#map-tip').outerWidth(), tiph=$('#map-tip').outerHeight(), 
					x=(x+tipw>$(document).scrollLeft()+$(window).width())? x-tipw-(20*2) : x
					y=(y+tiph>$(document).scrollTop()+$(window).height())? $(document).scrollTop()+$(window).height()-tiph-10 : y
					$('#map-tip').css({left:x + 20, top:y - 85});
					x2 = x - 20;
					y2 = y - 56;
					$('#map-icon').css({left:x2, top:y2})
				})
			}	
		}
	}

	// $('#mobile-map-dropdown').on('change', function(e){
	// 	e.preventDefault();
	// 	display_map_details($('#mobile-map-dropdown').val());
	// })

	// function display_map_details(map_id){
	// 	$('.active-resource-map').css({'stroke-width':'1','stroke':map_config['default']['borderColor']});
	// 	$('.active-resource-map').attr('class','');

	// 	$('#'+map_id).attr('class','active-resource-map');
	// 	$('#'+map_id).css({'stroke-width':'8','stroke':'#65b561'});

	// 	var _container = $('#map-region-details');

	// 	if(_map_details[map_id] !== undefined){

	// 		_container.animate({opacity: 0}, 400, function(){
	// 			_container.html('');	

	// 			$.each(_map_details[map_id],function(){
	// 				var details = this;
	// 				var links = "";

	// 				$.each(details.links,function(){
	// 					links += '<div class="map-link"><h3>'+this.label+'</h3>';
	// 					links += '<a href="'+this.link+'" target="_blank" class="fp-button">'+this.button_label+'</a></div>';
	// 				});

	// 				var _html = '<div class="map-details"><h3 class="title">'+details.title+'</h3><div class="map-links">'+links+'</div></div>';
	// 				_container.append(_html);
	// 			});

	// 			_container.animate({opacity: 1}, 400);
	// 		});
	// 	}else{

	// 		_container.animate({opacity: 0}, 400, function(){
		
	// 			var _html = '<div class="directions">No Resource Available yet for this County</div>';
	// 			_container.html(_html);

	// 			_container.animate({opacity: 1}, 400);
	// 		});
	// 	}

	// 	$('body').animate({
 //          scrollTop: _container.offset().top - 150
 //        }, 600);
	// }

})(jQuery)
